import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import instagramLogo from '../../images/instagram_logo.svg';
import facebookLogo from '../../images/facebook_logo.svg';
import arribaLogo from '../../images/arriba_logo.svg';
import nixLogo from '../../images/nix_logo.svg';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  ARRIBA_LINK,
  NIX_LINK,
  CONTACT_PHONE_NUMBER,
  CONTACT_EMAIL,
  CURRENT_YEAR,
} from '../../constants/constants';

const Footer = () => {
  const { t } = useTranslation();
  const openNotification = () => {
    const closeBtn = (
      <div className="copied-notif-close-btn" onClick={() => notification.close()}>
        <i className="icon-closed"></i>
      </div>
    );

    notification.config({
      placement: 'bottomRight',
      bottom: 50,
      duration: 3,
      closeIcon: closeBtn,
    });

    notification.open({
      message: t('copied_to_clipboard'),
      style: {
        borderRadius: '10px',
        minWidth: '280px',
        maxWidth: '340px',
        margin: 0,
        fontWeight: 500,
        fontSize: '14px',
        background: '#ECF0FD',
        boxShadow: 'none',
      },
      type: 'success',
    });
  };

  return (
    <footer className="footer">
      <div className="content-container">
        <div className="footer-top">
          <div className="footer-top-container">
            <div className="tile-content">
              <p className="tile-content-text">{t('footer_text')}</p>
              <div className="tile-content-btn">
                {t('footer_portal')}
                <i
                  className={`tile-content-icon icon-copy`}
                  onClick={() => {
                    navigator.clipboard.writeText('https://uav.volonterska.com.ua/');
                    openNotification();
                  }}
                />
              </div>
            </div>
            <div className="tile-created">
              <p className="tile-created-text">{t('footer_founders')}</p>
              <div className="tile-created-logos">
                <a
                  href={ARRIBA_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="created-logo-link link-default"
                >
                  <img src={arribaLogo} alt="logo Arriba" />
                </a>
                <a href={NIX_LINK} target="_blank" rel="noopener noreferrer" className="created-logo-link link-default">
                  <img src={nixLogo} alt="logo Nixsolutions" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-top-contacts">
            <span>{t('footer_contact_text')}</span>
            <div className="contacts-tile">
              <div className="contacts-icon">
                <i className="icon-phone" />
              </div>
              <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER}</a>
            </div>
            <div className="contacts-tile">
              <div className="contacts-icon">
                <i className="icon-email" />
              </div>
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-container">
            <div className="social">
              <a
                href={INSTAGRAM_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="social-logo-link link-default"
              >
                <img className="social-logo-img" src={instagramLogo} alt="logo instagram" />
              </a>
              <a
                href={FACEBOOK_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="social-logo-link link-default"
              >
                <img className="social-logo-img" src={facebookLogo} alt="logo facebook" />
              </a>
            </div>
            <div className="payments">
              <div className="payments-icon-visa">
                <i className="icon-visa" />
              </div>
              <div className="payments-icon-mastercard">
                <i className="icon-mastercard" />
              </div>
            </div>
          </div>
          <div className="rights">
            <span>
              {t('footer_rights')} {CURRENT_YEAR}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
