import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginUserRequest } from '../../../requests/user';

const loginUser = createAsyncThunk('login_user', async (credentials) => {
  const response = await loginUserRequest(credentials);
  localStorage.setItem('DD-token', response.data.token);
  return response.data;
});

export default loginUser;
