import { createSlice } from '@reduxjs/toolkit';
import loginUser from '../actions/user/loginUser';
import logoutUser from '../actions/user/logoutUser';

const initialState = {
  userInfo: {},
  isLoading: false,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
      state.error = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.error =
        action.error.message === 'Request failed with status code 401'
          ? 'Помилка входу: Ваш email або пароль неправильні'
          : action.error.message === 'Request failed with status code 422'
          ? 'Помилка входу: Поля мають бути заповнені'
          : action.error.message;
    },

    [logoutUser.pending]: (state) => {
      state.isLoading = true;
    },
    [logoutUser.fulfilled]: (state) => {
      state.userInfo = {};
      state.isLoading = false;
    },
    [logoutUser.rejected]: (state) => {
      state.userInfo = {};
      state.isLoading = false;
    },
  },
});

export default userSlice.reducer;
