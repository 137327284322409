import axios from 'axios';

export const fetchSector = async (args) => {
  args = {
    startData: args.from,
    finishData: args.to,
    orderBy: args.order,
    page: args.page,
  };
  args = Object.keys(args)
    .filter((key) => args[key] !== undefined)
    .reduce((obj, key) => {
      return { ...obj, [key]: args[key] };
    }, {});
  return axios({
    method: 'get',
    // eslint-disable-next-line no-undef
    url: `${process.env['REACT_APP_API_URL']}/api/v1/sector/get`,
    params: args,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('DD-token')}`,
    },
  });
};
