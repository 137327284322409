import React from 'react';
import HeaderLogo from '../../components/HeaderLogo/HeaderLogo';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logoutUser from '../../store/actions/user/logoutUser';

const Header = ({ admin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const switchLanguage = async (event) => {
    const lang = event.target.innerText.toLowerCase();
    await i18next.changeLanguage(lang);
    document.body.lang = lang;
  };

  const logOut = async () => {
    await dispatch(logoutUser());
    localStorage.removeItem('DD-token');
    navigate('/');
  };

  return (
    <header className="header">
      <HeaderLogo />
      <div className="header-lang-switcher">
        <button className="lang-switcher-btn" onClick={switchLanguage}>
          {t('change_language')}
        </button>
        {admin && (
          <button className="logout-btn btn-default" onClick={logOut}>
            <span>admin</span>
            <i className="icon-logout"></i>
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
