import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import blockReducer from './reducers/block';
import userReducer from './reducers/user';
import orderReducer from './reducers/order';
import sectorReducer from './reducers/sector';
import mapReducer from './reducers/map';

const rootReducer = combineReducers({
  blockReducer,
  userReducer,
  orderReducer,
  sectorReducer,
  mapReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};
