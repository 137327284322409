import axios from 'axios';

export const loginUserRequest = async (credentials) => {
  const requestData = JSON.stringify(credentials);

  return axios({
    method: 'post',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/login`, // eslint-disable-line
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    data: requestData,
  });
};

export const logoutUserRequest = async () => {
  return axios({
    method: 'post',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/logout`, // eslint-disable-line
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${localStorage.getItem('DD-token')}`,
    },
    data: {},
  });
};
