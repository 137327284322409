import axios from 'axios';

export const createAndSendOrder = async (order) => {
  const formData = new FormData();
  formData.append('color', order.color);
  formData.append('title', order.title);
  formData.append('image', order.image);
  formData.append('blocks', JSON.stringify(order.hexList));

  return axios({
    method: 'post',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/payment`, // eslint-disable-line
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const updateBlocksInOrder = async (block) => {
  const formData = new FormData();
  formData.append('id', block.id);
  formData.append('title', block.title);
  formData.append('image', block.image);

  const config = {
    method: 'post',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/sector/update`, // eslint-disable-line
    headers: {
      Authorization: `Bearer ${localStorage.getItem('DD-token')}`,
    },
    data: formData,
  };

  return axios(config);
};
