import React from 'react';
import { useTranslation } from 'react-i18next';
import roadsImage from '../../images/error_page_image.png';

const ErrorSection = () => {
  const { t } = useTranslation();
  return (
    <section className="error-section">
      <div className="content-container">
        <div className="error-section-content">
          <div className="error-content-body">
            <div className="error-section-lightbox">
              <h1 className="lightbox-title">{t('error_section_lightboxTitle')}</h1>
              <span className="lightbox-text">{t('error_section_lightboxText')}</span>
            </div>
            <div className="error-section-image">
              <img src={roadsImage} alt="kharkiv logo" />
            </div>
          </div>

          <a href="/" className="error-section-btn btn-default">
            {t('error_section_btnText')}
          </a>
          <p className="error-section-subtext">{t('error_section_description')}</p>
        </div>
      </div>
    </section>
  );
};

export default ErrorSection;
