import React, { useState } from 'react';
import logo from '../../images/volonterska_logo.svg';
import sideImage from '../../images/login_side_image.png';
import Input from '../../components/Input';
import { useDispatch, useSelector } from 'react-redux';
import loginUser from '../../store/actions/user/loginUser';
import { useNavigate } from 'react-router-dom';

const loginFormTitle = 'Вхід';
const loginBtnTitle = 'Увійти';

const LoginSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setLogin] = useState('');
  const [isPassword, setPassword] = useState('');
  const error = useSelector((state) => state.userReducer.error);

  const sendLoginData = async (event) => {
    event.preventDefault();
    const response = await dispatch(loginUser({ email: isLogin, password: isPassword }));
    if (!response.error) {
      navigate('/admin');
    }
  };

  return (
    <section className="login-section">
      <div className="tile-login">
        <form className="login-body">
          <div className="login-form">
            <span className="login-title">{loginFormTitle}</span>

            <div className="login-form-content">
              <Input
                id="inputText"
                name="inputText"
                type="text"
                maxlength="50"
                label="Логін"
                placeholder="Ваш email"
                state={isLogin}
                stateUpdate={setLogin}
                error={!!error}
                inputClassName="input-text"
                labelClassName="input-label"
                className="form-field-text"
              />

              <Input
                id="inputPassword"
                name="inputPassword"
                type="password"
                maxlength="50"
                label="Пароль"
                placeholder="Пароль"
                state={isPassword}
                stateUpdate={setPassword}
                error={!!error}
                inputClassName="input-password"
                labelClassName="input-label"
                className="form-field-password"
              />
            </div>
          </div>
          <button className="login-btn btn-default" onClick={sendLoginData}>
            {loginBtnTitle}
          </button>
          {error && <span className="input-error-message">{error}</span>}
        </form>
      </div>
      <div className="tile-login-image">
        <div className="login-logo">
          <img src={logo} alt="logo volonterska" />
        </div>
        <div className="login-image">
          <img src={sideImage} alt="side image map Kharkiv" />
        </div>
      </div>
    </section>
  );
};

export default LoginSection;
