import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  values: {
    scale: 0,
    translateX: 0,
    translateY: 0,
  },
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setPosition: function (state, action) {
      state.values = action.payload;
    },
  },
});

export const { setPosition } = mapSlice.actions;

export default mapSlice.reducer;
