import { createSlice } from '@reduxjs/toolkit';
import getAllBlocks from '../actions/block/getAllBlocks';
import getCurrentBlock from '../actions/block/getCurrentBlock';
import putCurrentBlock from '../actions/block/putCurrentBlock';
import getTotal from '../actions/block/getTotal';

const initialState = {
  total: 0,
  blocks: [],
  currentBlock: [],
  isLoading: false,
  error: null,
  isEditing: false,
};

export const blockSlice = createSlice({
  name: 'block',
  initialState,
  reducers: {
    setTitleInCurrentBlock: function (state, action) {
      state.currentBlock.title = action.payload;
    },
    setImageInCurrentBlock: function (state, action) {
      state.currentBlock.image = action.payload;
    },
    removeImageInCurrentBlock: function (state) {
      state.currentBlock.image = null;
    },
    setErrorInCurrentBlock: function (state, action) {
      state.error = action.payload;
    },
    removeErrorInCurrentBlock: function (state) {
      state.error = null;
    },
    removeCurrentBlock: function (state) {
      state.currentBlock = [];
    },
    setIsEditing: function (state, action) {
      state.isEditing = action.payload;
    },
  },
  extraReducers: {
    [getAllBlocks.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllBlocks.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.blocks = action.payload;
    },
    [getAllBlocks.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    [getCurrentBlock.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentBlock.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentBlock = action.payload;
    },
    [getCurrentBlock.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    [putCurrentBlock.pending]: (state) => {
      state.isLoading = true;
    },
    [putCurrentBlock.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentBlock = action.payload;
    },
    [putCurrentBlock.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    [getTotal.pending]: (state) => {
      state.isLoading = true;
    },
    [getTotal.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload.collected;
    },
    [getTotal.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const {
  setImageInCurrentBlock,
  setTitleInCurrentBlock,
  removeImageInCurrentBlock,
  setErrorInCurrentBlock,
  removeErrorInCurrentBlock,
  removeCurrentBlock,
  setIsEditing,
} = blockSlice.actions;

export default blockSlice.reducer;
