import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

const Input = ({
  id,
  name,
  type,
  maxlength,
  label,
  placeholder,
  state,
  stateUpdate,
  error,
  inputClassName,
  labelClassName,
  className,
  disabled,
  spaces,
}) => {
  const { t } = useTranslation();
  const hasError = error ? 'input-error' : '';

  const [isInputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    isInputType === 'text' ? setInputType('password') : setInputType('text');
  };

  const updateValue = (event) => {
    spaces ? stateUpdate(event.target.value) : stateUpdate(event.target.value.trim());
  };

  const openNotification = () => {
    const closeBtn = (
      <div className="copied-notif-close-btn" onClick={() => notification.close()}>
        <i className="icon-closed"></i>
      </div>
    );

    notification.config({
      placement: 'bottomRight',
      bottom: 50,
      duration: 3,
      closeIcon: closeBtn,
    });

    notification.open({
      message: t('copied_to_clipboard'),
      style: {
        borderRadius: '10px',
        minWidth: '280px',
        maxWidth: '340px',
        margin: 0,
        fontWeight: 500,
        fontSize: '14px',
        background: '#ECF0FD',
        boxShadow: 'none',
      },
      type: 'success',
    });
  };

  return (
    <div className={`form-field ${className} ${hasError}`}>
      <label htmlFor={id} className={`input-label ${labelClassName || ''} ${state.length ? 'input-valid' : ''}`}>
        {label}
      </label>
      <input
        placeholder={placeholder}
        value={state}
        className={`input ${inputClassName}`}
        id={id}
        name={name}
        type={isInputType}
        maxLength={maxlength}
        disabled={disabled}
        onChange={updateValue}
        onBlur={() => {}}
      />
      {type === 'password' && (
        <i
          className={`input-icon ${isInputType === 'text' ? 'icon-eye' : 'icon-eye-closed'}`}
          onClick={togglePasswordVisibility}
        />
      )}
      {id === 'shareInputText' && (
        <i
          className={`input-icon icon-copy`}
          onClick={() => {
            navigator.clipboard.writeText(state);
            openNotification();
          }}
        />
      )}
    </div>
  );
};

export default Input;
