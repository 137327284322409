import { createSlice } from '@reduxjs/toolkit';
import sendOrder from '../actions/order/sendOrder';

const initialState = {
  order: {
    hexList: [],
    color: 'sky',
    title: '',
    image: null,
  },
  paymentLink: null,
  isLoading: false,
  error: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addHexInOrder: function (state, action) {
      state.order.hexList.push(action.payload);
    },
    removeHexInOrder: function (state, action) {
      state.order.hexList = state.order.hexList.filter((hexId) => action.payload !== hexId);
    },
    removeLastHexInOrder: function (state) {
      state.order.hexList = state.order.hexList.slice(0, -1);
    },
    removeAllHexInOrder: function (state) {
      state.order.hexList = [];
    },
    setHexColorInOrder: function (state, action) {
      state.order.color = action.payload;
    },
    setTitleInOrder: function (state, action) {
      state.order.title = action.payload;
    },
    setImageInOrder: function (state, action) {
      state.order.image = action.payload;
    },
    removeImageInOrder: function (state) {
      state.order.image = null;
    },
    setErrorInOrder: function (state, action) {
      state.error = action.payload;
    },
    removeErrorInOrder: function (state) {
      state.error = null;
    },
    resetPayment: function (state) {
      state.paymentLink = null;
      state.error = null;
    },
  },
  extraReducers: {
    [sendOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [sendOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paymentLink = action.payload;
      state.error = null;
    },
    [sendOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const {
  addHexInOrder,
  removeHexInOrder,
  removeLastHexInOrder,
  removeAllHexInOrder,
  setHexColorInOrder,
  setTitleInOrder,
  setImageInOrder,
  removeImageInOrder,
  setErrorInOrder,
  removeErrorInOrder,
  resetPayment,
} = orderSlice.actions;

export default orderSlice.reducer;
