import React from 'react';
import Footer from '../../sections/Footer';
import Header from '../Header';

const SiteContainer = (props) => {
  const authAdmin = !!localStorage.getItem('DD-token');

  return (
    <div className="site-container">
      <Header admin={authAdmin} />
      <main className="main">{props.children}</main>
      {props.footer && <Footer />}
    </div>
  );
};

export default SiteContainer;
