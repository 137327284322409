import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';

const lng =
  localStorage.getItem('i18nextLng') || (['ua', 'ru'].includes(navigator.language.split('-')[0]) ? 'ua' : 'en');

const useLanguages = () => {
  i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(I18NextHttpBackend)
    .init({
      supportedLngs: ['ua', 'en'],
      debug: false,
      lng,
      fallbackLng: 'ua',
      detection: {
        order: ['localStorage', 'htmlTag'],
        htmlTag: document.body,
      },
      backend: {
        loadPath: '/assets/locales/{{lng}}/translations.json',
      },
      react: { useSuspense: true },
    });
};

export default useLanguages;
