import React from 'react';
import logo from '../../images/volonterska_logo.svg';

const HeaderLogo = () => {
  return (
    <div className="header-logo">
      <a href="https://www.volonterska.com.ua/" className="header-logo-link" target="_blank" rel="noreferrer">
        <img src={logo} alt="logo volonterska" />
      </a>
    </div>
  );
};

export default HeaderLogo;
