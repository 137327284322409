import 'antd/dist/antd.min.css';
import './scss/main.scss';
import React, { useEffect } from 'react';
import Router from './Router';
import { useDispatch } from 'react-redux';
import getAllBlocks from './store/actions/block/getAllBlocks';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllBlocks());
  }, []);

  return <Router />;
};

export default App;
