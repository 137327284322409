import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import CircleLoader from './components/CircleLoader';
const MainPage = lazy(() => import('./pages/MainPage'));
const ControlPanelPage = lazy(() => import('./pages/ControlPanelPage'));
const MainAdminPage = lazy(() => import('./pages/MainAdminPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));

const Router = () => {
  function PrivateOutlet() {
    const auth = !!localStorage.getItem('DD-token');
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }

  function LoginOutlet() {
    const auth = !!localStorage.getItem('DD-token');
    return auth ? <Navigate to="/" /> : <Outlet />;
  }

  return (
    <Suspense fallback={<CircleLoader />}>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/hex/:id" element={<ControlPanelPage />} />
        <Route path="/congrats" element={<ControlPanelPage congrats />} />

        <Route path="/" element={<LoginOutlet />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route path="/" element={<PrivateOutlet />}>
          <Route path="admin/hex/:id" element={<AdminPage />} />
          <Route path="admin" element={<MainAdminPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} status={404} />
      </Routes>
    </Suspense>
  );
};

export default Router;
