import React from 'react';
import SiteContainer from '../components/SiteContainer';
import ErrorSection from '../sections/ErrorSection';

const NotFoundPage = () => {
  return (
    <SiteContainer>
      <ErrorSection />
    </SiteContainer>
  );
};

export default NotFoundPage;
