import { createSlice } from '@reduxjs/toolkit';
import getSector from '../actions/sector/getSector';

const initialState = {
  sectors: [],
  isLoading: false,
  error: null,
};

export const sectorSlice = createSlice({
  name: 'sector',
  initialState,
  reducers: {},
  extraReducers: {
    [getSector.pending]: (state) => {
      state.isLoading = true;
    },
    [getSector.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sectors = action.payload;
    },
    [getSector.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default sectorSlice.reducer;
