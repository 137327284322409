import axios from 'axios';

export const fetchAllBlocks = async () => {
  return axios({
    method: 'get',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/blocks`, // eslint-disable-line
  });
};

export const fetchBlockById = async (id) => {
  return axios({
    method: 'get',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/blocks/${id}`, // eslint-disable-line
  });
};

export const fetchTotal = async () => {
  return axios({
    method: 'get',
    url: `${process.env['REACT_APP_API_URL']}/api/v1/order/collected`, // eslint-disable-line
  });
};
